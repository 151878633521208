import React, { createContext, useState, useEffect } from "react";

const defaultThemeState = {
  isDark: false,
  isMobile: true,
  scroll: false,
  showModal: false,
  showModalSearch: false,
  handleDark: () => {},
  handleShowModal: () => {},
  handleCloseModal: () => {},
  handleShowModalSearch: () => {},
  handleCloseModalSearch: () => {},
  // handleThemeName: () => { },
};

const ThemeContext = createContext(defaultThemeState);

const supportsDarkMode = () =>
  window.matchMedia("(prefers-color-scheme: dark)").matches === true;

const ThemeProvider = ({ children }) => {

  const [isMobile, setIsMobile] = useState(true);
  useEffect(() => {
    setIsMobile(window.matchMedia("(max-width: 768px)").matches);
  }, []);

  const [isDark, setIsDark] = useState(false);
  const handleDark = () => {
    setIsDark(!isDark);
    localStorage.setItem("isDark", JSON.stringify(isDark));
    isDark
      ? document.body.classList.add("dark-mode")
      : document.body.classList.remove("dark-mode");
  };

  const [showModal, setShowModal] = useState(false);
  const [showModalSearch, setShowModalSearch] = useState(false);



  const handleShowModal = () =>
    {
      setShowModal(true);
    };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowModalSearch(false);
  };

  const handleShowModalSearch = () => {
    setShowModalSearch(true);
  };

  const handleCloseModalSearch = () => {
    setShowModalSearch(false);
  };

  useEffect(() => {
    const lsDark = JSON.parse(localStorage.getItem("isDark"));
    if (lsDark) {
      setIsDark({ lsDark });
    } else if (supportsDarkMode()) {
      setIsDark(true);
    } // handleThemeName('theme-2')
  }, []);

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 260);
    });
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        isDark,
        // themeName,
        handleDark: handleDark,
        // handleThemeName: handleThemeName,
        showModal,
        showModalSearch,
        handleShowModal: handleShowModal,
        handleCloseModal: handleCloseModal,
        handleShowModalSearch: handleShowModalSearch,
        handleCloseModalSearch: handleCloseModalSearch,
        scroll: scroll,
        isMobile: isMobile,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeProvider, ThemeContext };
