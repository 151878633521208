import React from "react"

import "./src/styles/main.screen.css"
// import "./src/styles/slick/slick.css";
// import "./src/styles/slick/slick-theme.css";


import { ThemeProvider } from "./src/context/ThemeContext"

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
)
